#box-whatsapp {
    background-color: #ebeae6;
    background-image: url(../img/whatsapp-bg.jpg);
    background-position: center;
    width: 340px;
    height: 432px;
    position: fixed;
    overflow: hidden;
    bottom: 0;
    right: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: none;
    z-index: 999;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);

    .header {
        background-color: #006666;
        width: 100%;
        height: 50px;
        float: left;
        padding: 6px;
        overflow: hidden;
        
        .img {
            background-color: #fff;
            width: 37px;
            height: 37px;
            float: left;
            overflow: hidden;
            border-radius: 50%;
        }

        .title{
            width: calc(100% - 55px);
            float: left;
            padding-left: 10px;
            padding-top: 3px;
            
            strong {
                width: 100%;
                float: left;
                color: #fff;
                text-transform: uppercase;
                line-height: 18px;
                font-size: 15px;
            }
            
            span {
                width: 100%;
                float: left;
                color: #fff;
                line-height: 18px;
                font-size: 13px;
            }
        }

        .close-whatsapp {
            float: left;
            color: #fff;
            padding-top: 6px;
        }
    }

    .content {
        width: 100%;
        float: left;
        padding: 15px;
        height: 381px;
        overflow:auto;

        &::-webkit-scrollbar {
            width: 12px;
        }
          
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 10px;
        }
           
        &::-webkit-scrollbar-thumb {
            background: #006666; 
            border-radius: 10px;
        }
          
        &::-webkit-scrollbar-thumb:hover {
            background: #006666; 
        }
        
        form {
            width: 100%;
            float: left;
        
            .msg {
                width: 90%;
                padding: 10px 15px;
                margin-bottom: 15px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                border-radius: 7px;
                font-size: 15px;
                line-height: 18px;
                opacity: 0;
                display:none;
                
                &.type1 {
                    background-color: #fff;
                    float: left;
                }
                
                &.msg.type2{
                    background-color: #ccffcc;
                    float: right;
                    padding-top: 15px;
                    padding-bottom: 10px;
                }

                &.msg-setor-escolhido{
                    b{
                        color:#006666;
                        font-weight:bold;
                    }

                    a{
                        color:#333;
                        text-decoration:underline;
                    }
                }
            
                .form-group {
                    width: 100%;
                    float: left;
                    margin-bottom: 5px;
                    
                    .form-control {
                        width: 100%;
                        height: 37px;
                        float: left;
                        border-radius: 5px;
                        border: 1px solid #339966;
                    }

                    input[name="cidade"]{
                        margin-left: -25px;
                        width: calc(100% + 25px);
                    }
                }

                .reg-setor-whatsapp{
                    background-color:#fff;
                    width:100%;
                    float:left;
                    margin-top:10px;
                    padding:10px;
                    border-radius: 7px;
                    border:1px solid #006666;
                    color:#006666;
                    transition:all 0.4s;
                    font-weight:bold;
                    font-size: 14px;

                    &:hover{
                        background-color:#006666;
                        color:#fff;
                    }
                }
            }
            
            .btn {
                background-color: #006666;
                border-color: #006666;
                font-size: 18px;
                padding: 13px;
                font-weight: bold;
                text-transform: uppercase;
                border-radius: 10px;
                display:none;
                color:#fff;
                width: 100%;

                &:hover{
                    background-color: #004646;
                    border-color: #004646;
                    color:#fff;
                }
            }
        }
    }
}

#flutuante-whatsapp{
    float:left;
    position:fixed;
    z-index: 999;
    right: 35px;
    bottom: 42px;
    float: left;
    width: 50px;
}

#whatsapp i{
    display:none;
}

@media(max-width:767px){
    #whatsapp i{
        display:block;
    }   

    #flutuante-whatsapp{
        bottom: 15px;
        right: 15px;
    }
}

.pulse {
    animation: pulse 0.7s infinite;
    margin: 0 auto;
    display: table;
    margin-top: 50px;
    animation-direction: alternate;
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1);
      -webkit-filter: brightness(100%);
    }
    100% {
      -webkit-transform: scale(1.1);
      -webkit-filter: brightness(140%);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      filter: brightness(100%);
    }
    100% {
      transform: scale(1.1);
      filter: brightness(140%);
    }
  }



