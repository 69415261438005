@import "reset";
@import "whatsapp";

@import "../fonts";
@import "../colors";


body{
    overflow-x:hidden;
}
html {
    scroll-behavior: smooth;
  }
*{
    outline: none !important;
}
a{
    text-decoration: none;
}

.center{
    width: 1230px;
    position: relative;
    margin: 0 auto;
}
.inner-center{
    padding: 0 15px;
}





.hide-lp{
    display: none !important;
}
.container-plus{
    max-width: 1920px;
    margin: 0 auto;
}
h21{
    font-weight: 300;
    font-size: 38px;
    line-height: 49px;
    text-align: center;
    color: #fff;
}
.clear { clear: both; }

@media(max-width:1199px){
	[data-aos^=fade][data-aos^=fade],
	[data-aos^=zoom][data-aos^=zoom]{
		opacity: 1;
		transform:none;
	}
}

.lg-backdrop{
    background-color:rgba(100, 147, 162, 0.9) !important;
}

.grt-cookie p{
	color: #2d5e77;
	a{
		color: #2d5e77;
	}
}
.hide{
    display: none !important;
}
html {
    position:relative;
    //overflow-x:visible !important;
  }
  html.add-modal{
    overflow-x:visible !important;
  }
html > body#project.modal-open{
    overflow:hidden !important;
    //position: absolute !important;
    overflow-x: hidden !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
#box-preloader{
    display:none;
}

a:hover,
a:focus{
    text-decoration:none;
}

@import 'header';
@import 'footer';
