
#footer{
    width:100%;
    float:left;
    overflow:hidden;
    background: #fff;
    margin-top: 22px;

    /*margin-bottom:70px;*/
    @media(max-width:768px){
        margin-bottom: 0;
    }


    .bg2{
        background-color:#fff;
        width:100%;
        padding:18px 0;
        float:left;
        overflow:hidden;
        color:#fff;
        font-size:13px;
        background: #fff;
        color: #316F9D;
        border-top: 0.5px solid #ccc;

        @media(max-width:992px){
            padding-bottom: 50px;
            font-size: 12px;
            text-align: center;
            padding-top: 18px;
            height: auto;
            padding: 15px 0;
            p{
                margin: 0;
            }
        }

        @media(max-width:767px){
            font-size:10px;
            text-align:center;
        }
        .text{
            display: flex;
            justify-content: center;
            align-items: center;
            @media(max-width:768px){
                display: block;
                line-height: 16px;
            }
            a:nth-child(1){
                text-decoration: underline;
                color:#316F9D;
            }
            p{
                margin: 0 20px;
            }
        }
    }
}
