/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 20, 2022 */

*{
     font-family: 'Montserrat', sans-serif;
}

@font-face {
    font-family: 'gothambold';
    src: url('../../public/assets/fonts/gotham-bold-webfont.woff2') format('woff2'),
         url('../../public/assets/fonts/gotham-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  
  
  
  @font-face {
    font-family: 'gothambook';
    src: url('../../public/assets/fonts/gotham-book-webfont.woff2') format('woff2'),
         url('../../public/assets/fonts/gotham-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }