/*
    Tripoli is a generic CSS standard for HTML rendering. 
    Copyright (C) 2007  David Hellsing

    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/

* {
      margin: 0;
      padding: 0;
      text-decoration: none;
      font-size: 1em;
      outline: none;
    }
    
    code, kbd, samp, pre, tt, var, textarea, input, select, isindex, listing, xmp, plaintext {
      font: inherit;
      font-size: 1em;
    }
    
    dfn, i, cite, var, address, em {
      font-style: normal;
    }
    
    th, b, strong, h1, h2, h3, h4, h5, h6 {
      font-weight: normal;
    }
    
    a, img, a img, iframe, form, fieldset, abbr, acronym, object, applet {
      border: none;
    }
    
    table {
      border: none;
      border-collapse: collapse;
      border-spacing: 0;
    }
    
    caption, th, td, center {
      text-align: left;
      vertical-align: top;
    }
    
    body {
      line-height: 1;
      background: white;
      color: black;
    }
    
    q {
      quotes: "" "";
    }
    
    ul, ol, dir, menu {
      list-style: none;
    }
    
    sub, sup {
      vertical-align: baseline;
    }
    
    a {
      color: inherit;
    }
    
    hr {
      display: none;
    }
    
    /* we don't need a visual hr in layout */
    
    font {
      color: inherit !important;
      font: inherit !important;
      color: inherit !important;
    }
    
    /* disables some nasty font attributes in standard browsers */
    
    marquee {
      overflow: inherit !important;
      -moz-binding: none;
    }
    
    blink {
      text-decoration: none;
    }
    
    nobr {
      white-space: normal;
    }
    
    input {
      -webkit-appearance: none;
      appearance: none;
      webkit-overflow-scrolling: touch;
    }
    
    textarea {
      -webkit-appearance: none;
      border-radius: 0;
    }
    
    input {
      &.text, &[type="text1"], &[type="button"], &[type="submit"] {
        -webkit-appearance: none;
        border-radius: 0;
      }
    }
    
    .input-checkbox {
      -webkit-appearance: none;
      border-radius: 0;
    }
    
    /*
    
    CHANGELOG
    
    23/8-07
    
    Added deprecated tags <listing>, <xmp> and <plaintext> in the code block
    
    Resorted to normal white-space in all code tags
    
    Disabled the deprecated <marquee>, <blink> and <nobr> tag in some browsers
    
    */