#header{
    position: fixed;
    z-index: 3;
    width: 100%;
    top: 0;
    background: #fff;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));

    .conteudo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0 4px;
        @media(max-width:767px){
            
            display: block;
            text-align: center;
        }

        h1{
            margin-bottom: 0;
        }
        h2{
            font-family: 'Montserrat', sans-serif;
            font-size: 21px;
            letter-spacing: -0.06em;
            line-height: 24px;
            color: #2d5e77;
            strong{
                font-weight: 600;

            }
            @media(max-width:768px){
            
                font-size: 17px;
            }
            @media(max-width:480px){
            
                display: none
            }
        }
    }
}